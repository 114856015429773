import React, { useRef, useState, useEffect } from 'react'
import classnames from 'classnames'
import { reverse } from 'ramda'

import { UpArrowIcon, DownArrowIcon } from 'components/svg'
import { getPriorityDataPoints } from 'utils/diamond'
import { parseCsvEnvVar } from 'utils/index'

import Header from './Header'
import Journey from './Journey'
import Section from './Section'
import { getSections } from './utils'
import classes from './styles.module.css'
import { FlagIcon } from 'components/FlagIcon'
import { testIds } from 'config/testIds'

// import Mock from './mocks/diamondPolishedSplitM.json'
// import Mock2 from './mocks/diamond.json'

export const Diamond = ({ diamond }) => {
  const containerRef = useRef()
  const [diamondContent, setDiamondContent] = useState(diamond)
  const [isExpanded, setIsExpanded] = useState(false)
  const PROPS_ORDER = parseCsvEnvVar('DIAMOND_PROPERTIES_ORDER')

  const getHeading = () => {
    if (!diamondContent) return ''

    return `${getPriorityDataPoints(diamondContent.current, diamondContent.current.lifecycle_state)
      .filter((x) => x !== '-' && (x !== null) & (x !== '') && x !== ' ')
      .join(', ')}`
  }

  const handleViewDiamondClick = () => {
    setIsExpanded(!isExpanded)
  }

  const handleBackToTopClick = () => {
    if (!containerRef.current) return

    containerRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const hideRestrictedProps = (props) => {
    const restricted = ['ERP ID', 'Box ID', 'Box Description', 'Rough Check']

    return props.filter((prop) => !restricted.includes(prop.label))
  }

  const orderProps = (props, order = PROPS_ORDER) => {
    return props.sort((a, b) => {
      const propAIndex = order.indexOf(a.key)
      const propBIndex = order.indexOf(b.key)

      if (propAIndex > propBIndex) {
        // propB is not in the order list, move ordered propA to left
        if (propBIndex === -1) return -1
        return 1
      } else if (propAIndex < propBIndex) {
        // propA is not in the order list, move ordered propB to left
        if (propAIndex === -1) return 1
        return -1
      } else return 0
    })
  }

  const sections = diamondContent
    ? getSections(diamondContent).map((section) => {
      return {
        ...section,
        props: orderProps(hideRestrictedProps(section.props)),
      }
    })
    : []

  useEffect(() => {
    if (!diamond) return

    setDiamondContent(diamond)
  }, [diamond]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!diamondContent) return null

  return (
    <div className={classes.diamondContainer} ref={containerRef}>
      <div className={classes.wrapper}>
        <div className={classes.positioner}>
          <div data-test-id={testIds.diamondExperience.headerTitle} className={classes.heading}>
            <FlagIcon
              style={{ height: '20px', paddingRight: '5px', aspectRatio: '1' }}
              provenance={diamond.current.provenance}
            />
            {getHeading()}
          </div>
          <Header diamond={diamondContent.current} />

          <div
            className={classnames(classes.viewDiamondDetails, { [classes.expanded]: isExpanded })}
            onClick={handleViewDiamondClick}
          >
            {isExpanded ? 'Hide' : 'View'} diamond details
            {isExpanded ? <UpArrowIcon /> : null}
            {!isExpanded ? <DownArrowIcon /> : null}
          </div>

          <div
            data-test-id={testIds.diamondExperience.viewHideDiamondDetailsButton}
            className={classnames(classes.diamondDetails, { [classes.expanded]: isExpanded })}
          >
            <div className={classnames(classes.journey, { [classes.visible]: sections.length > 1 })}>
              <Journey sections={sections} />
            </div>
            {reverse(sections).map((section) => {
              return (
                <div id={`${section.lifecycle}Section`} className={classes.section} key={section.lifecycle}>
                  <Section
                    testIdPrefix={testIds.diamondExperience.details.prefix}
                    diamond={diamondContent}
                    {...section}
                  />
                </div>
              )
            })}
          </div>
        </div>
        <button
          className={classnames(classes.backToTop, { [classes.visible]: isExpanded })}
          onClick={handleBackToTopClick}
          data-test-id={testIds.diamondExperience.backToTopButton}
        >
          BACK TO TOP <UpArrowIcon />
        </button>
      </div>
    </div>
  )
}

export default Diamond
