import { clone } from 'lodash'
import { isEmpty, pathOr, values } from 'ramda'
import { MatchedLabel } from 'components/MatchedLabel'
import { FlagIcon } from 'components/FlagIcon'

export const getDiamondCurrentStage = (diamond) => {
  if (diamond.lifecycle_state === 'rough') {
    if (diamond.diamond_parent_id || diamond.isSplit) return 'split'
    else return 'rough'
  } else if (diamond.current_stage) {
    return diamond.current_stage
  } else if (diamond.lifecycle_state === 'polished') return 'polished'
  else return null
}

const findLastImage = (images, type) => {
  const allByType = images.filter((image) => image.image_type === type)

  if (allByType.length > 0) {
    const reversed = allByType.reverse()
    return reversed[0]
  }

  return null
}

const ALL_KNOWN_IMAGE_TYPES = [
  'default',
  'face_up',
  '3d',
  'heart',
  'arrow',
  'ideal_scope',
  'aset_scope',
  'dark_field',
  'fluorescence',
  'inclusion_map',
  'plot',
  'b2b',
  'diagram',
]

const getAllCustomImages = (diamond, lifecycle, knownTypes = ALL_KNOWN_IMAGE_TYPES) => {
  const images = []
  const list = diamond[lifecycle].images ? diamond[lifecycle].images : []

  list.forEach((image) => {
    if (knownTypes.includes(image.image_type)) return

    images.push(image)
  })

  return images
}

const getMediaByTypeAndLifecycle = (diamond, lifecycle, key, type) => {
  const keys =
    isUsingV2Format(diamond) && lifecycle === 'intermediate'
      ? [lifecycle, diamond[lifecycle].length - 1, key]
      : [lifecycle, key]
  const image = pathOr([], keys, diamond)
  const images = image instanceof Array ? image : [image]
  return type ? findLastImage(images, type) : images[images.length - 1]
}

export const isSectionVisible = ({ fullDiamond, diamond, section }) => {
  let disabled_sections
  let isVisible = false

  if (!fullDiamond && !diamond) return isVisible

  switch (section) {
    case 'rough':
      // check if a single diamond entry has a rough object and it's not disabled
      if (diamond) {
        disabled_sections = diamond.disabled_sections || []

        isVisible = !!diamond.rough && !disabled_sections.includes('rough')
      } else {
        // find the root rough of the diamond and check if he has rough object and it's not disabled
        const rootRough = getRootDiamond(fullDiamond)

        disabled_sections = rootRough.disabled_sections || []

        isVisible = !!rootRough.rough && !disabled_sections.includes('rough')
      }

      break
    case 'intermediate':
      // check if a single diamond entry has an intermediate object and it's not disabled
      if (diamond) {
        disabled_sections = diamond.disabled_sections || []

        isVisible = !!diamond.intermediate && diamond.intermediate.length && !disabled_sections.includes('intermediate')
      } else {
        // check if there's atleast one intermediate diamond with intermediate data and its not disabled
        isVisible = !!getLatestAvailableIntermediate(fullDiamond)
      }

      break
    case 'polished':
      // check if a single diamond entry has a polished object and it's not disabled
      if (diamond) {
        disabled_sections = diamond.disabled_sections || []

        isVisible = diamond.polished && !disabled_sections.includes('polished')
      } else {
        const current = fullDiamond.current

        disabled_sections = current.disabled_sections || []

        isVisible = !!current.polished && !disabled_sections.includes('polished')
      }
      break
    default:
      break
  }

  return isVisible
}

export const getLatestAvailableIntermediate = (fullDiamond, dmd) => {
  const diamond = dmd || fullDiamond.current
  const parentDiamond = fullDiamond[diamond.diamond_parent_id]
  const disabled_sections = diamond.disabled_sections || []
  const isCurrent = diamond.diamond_id === fullDiamond.current.diamond_id

  if (diamond.polished && !isCurrent && !disabled_sections.includes('polished')) return diamond.polished
  else if (diamond.intermediate && diamond.intermediate.length && !disabled_sections.includes('intermediate')) {
    // if the latest intermediate stage is not disabled
    // try to get the rough object from the diamond
    if (diamond.intermediate.length === 1 && diamond.intermediate[0].is_disabled) {
      if (diamond.rough && parentDiamond && !disabled_sections.includes('rough')) return diamond.rough
    } else {
      return diamond.intermediate[diamond.intermediate.length - 1]
    }
  } else if (diamond.rough && parentDiamond && !disabled_sections.includes('rough')) return diamond.rough

  if (parentDiamond) return getLatestAvailableIntermediate(fullDiamond, parentDiamond)
  else return null
}

const filterNulls = (x) => x.filter((x) => x && !isEmpty(x))

const getImagesByLifeCycle = (diamond, lifecycle) => {
  switch (lifecycle) {
    case 'rough':
      return filterNulls(
        [
          getMediaByTypeAndLifecycle(diamond, 'rough', 'images', 'default'),
          getMediaByTypeAndLifecycle(diamond, 'rough', 'images', '3d'),
          getAllCustomImages(diamond, 'rough', ['default', '3d']),
        ].flat()
      )
    case 'intermediate':
    case 'split':
      return filterNulls(
        [
          getMediaByTypeAndLifecycle(diamond, 'intermediate', 'images', 'default'),
          getMediaByTypeAndLifecycle(diamond, 'intermediate', 'images', '3d'),
          getAllCustomImages(diamond, 'rough', ['default', '3d']),
        ].flat()
      )
    case 'polished':
      return filterNulls(
        [
          getMediaByTypeAndLifecycle(diamond, 'polished', 'images', 'default'),
          getMediaByTypeAndLifecycle(diamond, 'polished', 'images', 'face_up'),
          getMediaByTypeAndLifecycle(diamond, 'polished', 'images', '3d'),
          getMediaByTypeAndLifecycle(diamond, 'polished', 'images', 'heart'),
          getMediaByTypeAndLifecycle(diamond, 'polished', 'images', 'arrow'),
          getMediaByTypeAndLifecycle(diamond, 'polished', 'images', 'ideal_scope'),
          getMediaByTypeAndLifecycle(diamond, 'polished', 'images', 'aset_scope'),
          getMediaByTypeAndLifecycle(diamond, 'polished', 'images', 'dark_field'),
          getMediaByTypeAndLifecycle(diamond, 'polished', 'images', 'fluorescence'),
          getMediaByTypeAndLifecycle(diamond, 'polished', 'images', 'inclusion_map'),
          getMediaByTypeAndLifecycle(diamond, 'polished', 'images', 'plot'),
          getMediaByTypeAndLifecycle(diamond, 'polished', 'images', 'b2b'),
          getMediaByTypeAndLifecycle(diamond, 'polished', 'images', 'diagram'),
          getAllCustomImages(diamond, 'polished'),
        ].flat()
      )
    default:
      return []
  }
}

const getMediaTypeByLifeCycle = (diamond, lifecycle, mediaType) => {
  switch (lifecycle) {
    case 'rough':
      return filterNulls([getMediaByTypeAndLifecycle(diamond, 'rough', mediaType)])
    case 'intermediate':
      return filterNulls([getMediaByTypeAndLifecycle(diamond, 'intermediate', mediaType)])
    case 'polished':
      return filterNulls([getMediaByTypeAndLifecycle(diamond, 'polished', mediaType)])
    default:
      return []
  }
}

const defaultSupported = ['ply', 'stl']

const nullIfNotSupported = (media, supported = defaultSupported) => {
  if (!media) return null

  const extension = media.extension || media.filename.slice(media.filename.lastIndexOf('.') + 1)

  if (!extension) return media

  if (supported.includes(extension.toLowerCase())) return media
  else return null
}

const defaultOrder = ['videos', 'images', 'scans', 'plans', 'lifecycle', 'shape']

export const getOrderedMedia = (diamond, types = ['images'], order = defaultOrder) => {
  const results = order
    .concat(defaultOrder.filter((x) => order.indexOf(x) === -1))
    .filter((x) => types.includes(x))
    .map((type) => {
      switch (type) {
        case 'images':
          return getImagesByLifeCycle(diamond, diamond.lifecycle_state).map((x) => ({ ...x, type: 'image' }))

        case 'scans':
          return getMediaTypeByLifeCycle(diamond, diamond.lifecycle_state, 'scan')
            .map((x) => ({ ...x, type: 'scan' }))
            .map((scan) => nullIfNotSupported(scan))

        case 'plans':
          return getMediaTypeByLifeCycle(diamond, diamond.lifecycle_state, 'plan')
            .map((x) => ({ ...x, type: 'plan' }))
            .map((plan) => nullIfNotSupported(plan))

        case 'videos':
          return getMediaTypeByLifeCycle(diamond, diamond.lifecycle_state, 'videos').map((x) => ({
            ...x,
            type: 'video',
          }))

        case 'shape':
          if (diamond.lifecycle_state === 'polished') {
            const shape = pathOr('Other', [diamond.lifecycle_state, 'shape'], diamond)

            if (shape) {
              return { type: 'icon', a: 1, value: shape, variant: 'shape' }
            }
          }

          return null

        case 'lifecycle':
          return { type: 'icon', value: getDiamondCurrentStage(diamond), variant: 'lifecycle' }

        default:
          return null
      }
    })

  return filterNulls(results.flat())
}

const isUsingV2Format = (diamond) => pathOr(false, ['intermediate'], diamond) instanceof Array

const getGeneric = (diamond, lifecycle, key) => {
  if (isUsingV2Format(diamond)) {
    return getGenericV2(diamond, lifecycle, key)
  } else if (lifecycle === 'split') {
    return pathOr('-', `rough.${key}`.split('.'), diamond)
  }

  return pathOr('-', `${lifecycle}.${key}`.split('.'), diamond)
}

export const getGenericV2 = (diamond, lifecycle, key) => {
  if (['rough', 'polished'].includes(lifecycle)) {
    return pathOr('-', `${lifecycle}.${key}`.split('.'), diamond)
  } else {
    const lastPosition = diamond.intermediate.length - 1
    return pathOr('-', ['intermediate', lastPosition, key], diamond)
  }
}

export const formatNumber = (value, digit = 4) => {
  let x = value.toString()

  if (x.indexOf('.') === -1) {
    x = `${x}.`
  }

  x = `${x}0000000`

  const firstDotCharacterIndex = x.indexOf('.')
  return `${x.slice(0, firstDotCharacterIndex + digit)}`
}

export const caratToString = (value) => (typeof value === 'number' ? `${formatNumber(value)}ct` : '-')

export const getCarat = (diamond, lifecycle) => caratToString(getGeneric(diamond, lifecycle, 'carats'))

const colorToString = (value) => (value !== '-' ? `${value} Colour` : '-')
const getColor = (diamond, lifecycle) => colorToString(getGeneric(diamond, lifecycle, 'colour'))

export const getPriorityDataPoints = (diamond, lifecycle, withProvenance = true) => {
  const provenance = getFullProvenanceName(diamond.provenance)

  if (lifecycle === 'polished') {
    const result = [
      getCarat(diamond, lifecycle),
      getGeneric(diamond, lifecycle, 'shape'),
      getGeneric(diamond, lifecycle, 'clarity'),
      getColor(diamond, lifecycle),
    ]

    if (withProvenance) {
      result.unshift(provenance)
    }

    return result
  } else {
    const result = [
      getCarat(diamond, lifecycle),
      getColor(diamond, lifecycle),
      getGeneric(diamond, lifecycle, 'quality'),
      getGeneric(diamond, lifecycle, 'model'),
    ]

    if (withProvenance) {
      result.unshift(provenance)
    }

    return result
  }
}

export const getParticipantLogo = (name) => {
  if (!name) return null

  return `/images/logos/${name.toLowerCase()}.svg`
}

export const isVerified = (diamond) => pathOr(false, ['verification_status'], diamond) === 'Verified'
export const getTracrId = (diamond) => pathOr(null, ['diamond_id'], diamond)
export const getParticipantId = (diamond) => pathOr(null, ['participant_id'], diamond)

export const getProvenanceName = (diamond) => pathOr(null, ['provenance', 'provenance_name'], diamond)

export const getFullProvenanceName = (provenance) =>
  "country_code" in provenance && provenance.country_code ?
    new Intl.DisplayNames(['en'], { type: 'region' }).of(provenance.country_code) :
    provenance.provenance_name

// replace '-' & '' values with nulls
const nullifyRowValues = (rows) =>
  rows.map((row) => ({ ...row, value: row.value === '-' || !row.value ? null : row.value }))

const toRow = (label, value, options) => ({
  label,
  value,
  ...options,
})

const getArrayValues = (diamond, lifecycle, key, arrayKey, delimiter, defaultAttribute = '') => {
  const result = getGeneric(diamond, lifecycle, key)

  if (result instanceof Array) {
    return result
      .map((x) => x[arrayKey])
      .map((x) => x || defaultAttribute)
      .filter((x) => x)
      .join(delimiter)
  }

  return result
}

/**
 *  gets the last property value from the last object in an array of objects from a diamond's lifecycle property
 *  @param diamond the diamond in question
 *  @param lifecycle the stage of lifecycle that will be accessed
 *  @param key the key of the array property in the lifecycle object
 *  @param arrayKey the key of the property to gather from the object in the array specififed by the key param
 *  @param default value for the property selected by param arrayKey
 */
const getLastArrayValue = (diamond, lifecycle, key, arrayKey, defaultAttribute = '') => {
  const result = getArrayValues(diamond, lifecycle, key, arrayKey, ',', defaultAttribute).split(',')
  return result[result.length - 1]
}

const getIdFields = (diamond) => {
  const tracrIdKey = 'diamond_id'

  if (tracrIdKey === 'diamond_id') {
    return [
      toRow('Tracr ID', getTracrId(diamond), { isVerifiedProperty: true }),
      toRow('ERP ID', getParticipantId(diamond), { isVerifiedProperty: true }),
    ]
  }

  return toRow('ERP ID', getParticipantId(diamond), { isVerifiedProperty: true })
}

const formatRowValue = (row, mapper) => ({
  ...row,
  value: mapper(row.value),
})

const formatQuality = (quality) => {
  if (quality) {
    switch (quality.toLowerCase()) {
      case '1q':
        return '1st Quality'
      case '2q':
        return '2nd Quality'
      default:
        return quality
    }
  } else {
    return ''
  }
}

const formatClarity = (clarity) => {
  if (clarity) {
    switch (clarity.toLowerCase()) {
      case 'fl':
        return 'Flawless'
      case 'if':
        return 'Internally Flawless'
      default:
        return clarity
    }
  } else {
    return ''
  }
}

const toDigit = (digit, callback) => (value) => {
  if (value === '-') return callback(value)

  const newValue = formatNumber(value, digit)

  return callback(newValue)
}

const suffix = (suffix) => (value) => {
  if (value !== '-') {
    return `${value}${suffix}`
  }

  return value
}

export const getDiamondMeasurements = (diamond) => {
  return nullifyRowValues([
    formatRowValue(
      toRow('Table %', getGeneric(diamond, 'polished', 'table_percent'), { key: 'polished.table_percent' }),
      toDigit(3, suffix('%'))
    ),
    formatRowValue(
      toRow('Depth %', getGeneric(diamond, 'polished', 'depth_percent'), { key: 'polished.depth_percent' }),
      toDigit(3, suffix('%'))
    ),
    formatRowValue(
      toRow('Length', getGeneric(diamond, 'polished', 'length'), { key: 'polished.length' }),
      toDigit(3, suffix('mm'))
    ),
    formatRowValue(
      toRow('Width', getGeneric(diamond, 'polished', 'width'), { key: 'polished.width' }),
      toDigit(3, suffix('mm'))
    ),
    formatRowValue(
      toRow('Depth', getGeneric(diamond, 'polished', 'depth'), { key: 'polished.depth' }),
      toDigit(3, suffix('mm'))
    ),
    formatRowValue(
      toRow('Pav Angle', getGeneric(diamond, 'polished', 'pavillion_angle'), { key: 'polished.pavillion_angle' }),
      toDigit(3, suffix('\u00b0'))
    ),
    formatRowValue(
      toRow('Pav %', getGeneric(diamond, 'polished', 'pavillion_percent'), { key: 'polished.pavillion_percent' }),
      toDigit(3, suffix('%'))
    ),
    toRow('Girdle from', getGeneric(diamond, 'polished', 'girdle_thickness_from'), {
      key: 'polished.girdle_thickness_from',
    }),
    toRow('Girdle to', getGeneric(diamond, 'polished', 'girdle_thickness_to'), { key: 'polished.girdle_thickness_to' }),
    toRow('Girdle condition', getGeneric(diamond, 'polished', 'girdle_condition'), {
      key: 'polished.girdle_condition',
    }),
    toRow('Girdle size', getGeneric(diamond, 'polished', 'girdle_size'), { key: 'polished.girdle_size' }),
    toRow('Culet Condition', getGeneric(diamond, 'polished', 'culet_condition'), { key: 'polished.culet_condition' }),
    toRow('Culet Size', getGeneric(diamond, 'polished', 'culet_size'), { key: 'polished.culet_size' }),
    formatRowValue(
      toRow('Crown Angle', getGeneric(diamond, 'polished', 'crown_angle'), { key: 'polished.crown_angle' }),
      toDigit(3, suffix('\u00b0'))
    ),
    formatRowValue(
      toRow('Crown Height', getGeneric(diamond, 'polished', 'crown_height'), { key: 'polished.crown_height' }),
      toDigit(3, suffix('%'))
    ),
    formatRowValue(
      toRow('Diameter Minimum', getGeneric(diamond, 'polished', 'diameter_minimum'), {
        key: 'polished.diameter_minimum',
      }),
      toDigit(3, suffix('mm'))
    ),
    formatRowValue(
      toRow('Diameter Maximum', getGeneric(diamond, 'polished', 'diameter_maximum'), {
        key: 'polished.diameter_maximum',
      }),
      toDigit(3, suffix('mm'))
    ),
  ])
}

const getProvenanceProperty = (diamond) => {
  return getProvenanceName(diamond)
}

const getTopLevelProperty = (diamond, key) => {
  const value = pathOr(null, [key], diamond)
  const trimmed = typeof value === 'string' ? value && value.trim() : value

  if (trimmed) {
    return trimmed
  }

  return null
}

export const getCompactDiamondDetails = (diamond, lifecycle) => {
  switch (lifecycle) {
    case 'rough':
    case 'intermediate':
    case 'polished':
      return nullifyRowValues(
        [
          getIdFields(diamond, lifecycle),
          toRow('Provenance', getProvenanceProperty(diamond), { isVerifiedProperty: true }),
          toRow('Carat', getCarat(diamond, lifecycle)),
        ].flat()
      )

    default:
      return []
  }
}

function provenance_renderer(diamond, _label, value) {
  return (
    <span style={{ display: 'flex', columnGap: '7px', alignItems: 'center' }}>
      <FlagIcon style={{ height: '20px', paddingRight: '5px' }} provenance={diamond.provenance} />
      <span style={{ paddingTop: '1px' }}>{getFullProvenanceName(diamond.provenance)}</span>
    </span>
  )
}

export const getDiamondDetails = (diamond, lifecycle) => {
  if (lifecycle === 'rough') {
    return nullifyRowValues(
      [
        getIdFields(diamond, lifecycle),
        //toRow('Rough Check', getTopLevelProperty(diamond, 'matched'), { key:'matched', renderer:(_lable,value) => (value ? <MatchedLabel slim value={value} /> : <>-</>) }),
        toRow('Provenance', getProvenanceProperty(diamond), {
          isVerifiedProperty: true,
          key: 'provenance',
          renderer: (label, value) => provenance_renderer(diamond, label, value),
        }),
        toRow('Carat', getCarat(diamond, lifecycle), { key: 'rough.carats' }),
        toRow('Colour', getGeneric(diamond, lifecycle, 'colour'), { key: 'rough.colour' }),
        formatRowValue(
          toRow('Quality', getGeneric(diamond, lifecycle, 'quality'), { key: 'rough.quality' }),
          formatQuality
        ),
        toRow('Model', getGeneric(diamond, lifecycle, 'model'), { key: 'rough.model' }),
        toRow('Fluorescence Intensity', getGeneric(diamond, lifecycle, 'fluorescence_intensity'), {
          key: 'rough.fluorescence_intensity',
        }),
        toRow('Fluorescence Colour', getGeneric(diamond, lifecycle, 'fluorescence_colour'), {
          ke1pxy: 'rough.fluorescence_colour',
        }),
        toRow('Stress', getGeneric(diamond, lifecycle, 'stress'), { key: 'rough.stress' }),
        toRow('Fancy Colour', getGeneric(diamond, lifecycle, 'fancy_colour'), { key: 'rough.fancy_colour' }),
        toRow('Fancy Colour Intensity', getGeneric(diamond, lifecycle, 'fancy_colour_intensity'), {
          key: 'rough.fancy_colour_intensity',
        }),
        toRow('Fancy Colour Overtone', getGeneric(diamond, lifecycle, 'fancy_colour_overtone'), {
          key: 'rough.fancy_colour_overtone',
        }),
        toRow('Box ID', getTopLevelProperty(diamond, 'box_id'), { key: 'box_id' }),
        toRow('Box Description', getTopLevelProperty(diamond, 'box_description'), { key: 'box_description' }),
      ].flat()
    )
  }

  if (lifecycle === 'intermediate') {
    return nullifyRowValues(
      [
        getIdFields(diamond, lifecycle),
        toRow('Provenance', getProvenanceProperty(diamond), {
          isVerifiedProperty: true,
          key: 'provenance',
          renderer: (label, value) => provenance_renderer(diamond, label, value),
        }),
        toRow('Carat', getCarat(diamond, lifecycle)),
        toRow('Colour', getGeneric(diamond, lifecycle, 'colour')),
        formatRowValue(toRow('Quality', getGeneric(diamond, lifecycle, 'quality')), formatQuality),
        toRow('Model', getGeneric(diamond, lifecycle, 'model')),
        toRow('Fluorescence Intensity', getGeneric(diamond, lifecycle, 'fluorescence_intensity')),
        toRow('Fluorescence Colour', getGeneric(diamond, lifecycle, 'fluorescence_colour')),
        toRow('Stress', getGeneric(diamond, lifecycle, 'stress')),
      ].flat()
    )
  }

  if (lifecycle === 'polished') {
    return nullifyRowValues(
      [
        getIdFields(diamond, lifecycle),
        toRow('Provenance', getProvenanceProperty(diamond), {
          isVerifiedProperty: true,
          key: 'provenance',
          renderer: (label, value) => provenance_renderer(diamond, label, value),
        }),
        toRow('Shape', getGeneric(diamond, lifecycle, 'shape'), { key: 'polished.shape' }),
        toRow('Carat', getCarat(diamond, lifecycle), { key: 'polished.carats' }),
        toRow('Colour', getGeneric(diamond, lifecycle, 'colour'), { key: 'polished.colour' }),
        formatRowValue(
          toRow('Clarity', getGeneric(diamond, lifecycle, 'clarity'), { key: 'polished.clarity' }),
          formatClarity
        ),
        toRow('Cut', getGeneric(diamond, lifecycle, 'cut_grade'), { key: 'polished.cut_grade' }),
        toRow('Polish', getGeneric(diamond, lifecycle, 'polish_quality'), { key: 'polished.polish_quality' }),
        toRow('Symmetry', getGeneric(diamond, lifecycle, 'symmetry'), { key: 'polished.symmetry' }),
        toRow('Fluorescence Intensity', getGeneric(diamond, lifecycle, 'fluorescence_intensity'), {
          key: 'polished.fluorescence_intensity',
        }),
        toRow('Fluorescence Colour', getGeneric(diamond, lifecycle, 'fluorescence_colour'), {
          key: 'polished.fluorescence_colour',
        }),
        toRow('Grading Lab', getLastArrayValue(diamond, lifecycle, 'grading_certificates', 'grading_lab'), {
          key: 'polished.grading_lab',
        }),
        toRow(
          'Certificate No.',
          getLastArrayValue(diamond, lifecycle, 'grading_certificates', 'grader_certificate_no'),
          { key: 'polished.certificate_no' }
        ),
        toRow(
          'Inscription',
          getArrayValues(diamond, lifecycle, 'grading_certificates', 'grader_inscription_no')?.split(',').join(', '),
          { key: 'polished.inscription_no' }
        ),
        toRow('Fancy Colour', getGeneric(diamond, lifecycle, 'fancy_colour'), { key: 'polished.fancy_colour' }),
        toRow('Fancy Colour Intensity', getGeneric(diamond, lifecycle, 'fancy_colour_intensity'), {
          key: 'polished.fancy_colour_intensity',
        }),
        toRow('Fancy Colour Overtone', getGeneric(diamond, lifecycle, 'fancy_colour_overtone'), {
          key: 'polished.fancy_colour_overtone',
        }),
        toRow('Box ID', getTopLevelProperty(diamond, 'box_id'), { key: 'box_id' }),
        toRow('Box Description', getTopLevelProperty(diamond, 'box_description'), { key: 'box_description' }),
        toRow('Comments', getArrayValues(diamond, lifecycle, 'grading_certificates', 'description', ', '), {
          key: 'polished.comments',
        }),
      ].flat()
    )
  }

  return []
}

export const getRootDiamond = (diamonds, id) => {
  const diamond = id ? diamonds[id] : diamonds.current

  if (!diamond) return null

  if (diamond.diamond_parent_id) return getRootDiamond(diamonds, diamond.diamond_parent_id)

  if (diamond.lifecycle_state === 'rough' || diamond.lifecycle_state === 'split') return clone(diamond)

  // otherwise return the diamond itself
  return diamond
}

// most recent split that is not root rough
export const getMostRecentSplit = (diamonds, id) => {
  const diamond = id ? diamonds[id] : diamonds.current

  if (diamond.lifecycle_state === 'split') {
    return diamond
  }

  if (!diamond.diamond_parent_id) {
    return null
  }

  return getMostRecentSplit(diamonds, diamond.diamond_parent_id)
}

export const aggregatedData = (current, diamond) => {
  // Recursively aggregates all diamonds with its parents and intermediates steps
  let array = []
  const aggregateData = (diamondObject) => {
    if (
      diamondObject.polished &&
      !diamondObject.polished.is_disabled &&
      current.diamond_id !== diamondObject.diamond_id
    ) {
      const modifiedPolished = {
        provenance: diamondObject.provenance,
        diamond_id: diamondObject.diamond_id,
        current_stage: diamondObject.current_stage,
        lifecycle_state: 'polished',
        is_current: diamondObject.is_current,
        ...(diamondObject.is_mock ? { is_mock: true } : null),
        id: diamondObject.diamond_id,
        polished: {
          ...diamondObject.polished,
        },
      }
      array.push(modifiedPolished)
    }

    if (diamondObject.intermediate && diamondObject.intermediate.length) {
      const modified = diamondObject.intermediate
        .map((intermediate) => {
          // do not show disabled intermediates
          if (intermediate.is_disabled) return null

          return {
            provenance: diamondObject.provenance,
            diamond_id: diamondObject.diamond_id,
            current_stage: intermediate.current_stage,
            lifecycle_state: 'intermediate',
            ...(diamondObject.is_mock ? { is_mock: true } : null),
            id: diamondObject.diamond_id,
            intermediate: {
              ...intermediate,
            },
          }
        })
        .filter((intermediate) => intermediate)
        .reverse()

      array = [...array, ...modified]
    }

    if (diamondObject.rough && !diamondObject.rough.is_disabled && diamondObject.diamond_parent_id) {
      const modifiedRough = {
        provenance: diamondObject.provenance,
        diamond_id: diamondObject.diamond_id,
        current_stage: 'rough',
        lifecycle_state: 'rough',
        id: diamondObject.diamond_id,
        is_current: diamondObject.is_current,
        isSplit: !!diamondObject.diamond_parent_id,
        ...(diamondObject.is_mock ? { is_mock: true } : null),
        rough: {
          ...diamondObject.rough,
        },
      }
      array.push(modifiedRough)
    }

    if (diamondObject.diamond_parent_id) {
      aggregateData(diamond[diamondObject.diamond_parent_id])
    }
  }

  if (!current) {
    return []
  }

  aggregateData(current)

  return array.reverse()
}

export const getCertificateHash = (diamond) => {
  const certs = pathOr([], ['polished', 'grading_certificates'], diamond).filter(
    (file) => file.filename && file.filename.toLowerCase().endsWith('.pdf')
  )

  if (certs && certs[certs.length - 1]) return certs[certs.length - 1].hash
  else return null
}

export const isDeclaredDiamond = (diamond) => {
  if (!diamond) return null

  return /^declared$/i.test(diamond.assurance_level)
}
