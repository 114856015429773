import React from 'react'

export function FlagIcon({ provenance, style }) {
  if (!provenance) return null;
  if (provenance.country_code) return <img style={style} src={cc(provenance.country_code)} />
  switch (provenance.provenance_name.toLowerCase()) {
    case 'botswana':
      return <img style={style} src={cc("BW")} />
    case 'canada':
      return <img style={style} src={cc("CA")} />
    case 'namibia':
      return <img style={style} src={cc("NA")} />
    case 'south africa':
      return <img style={style} src={cc("ZA")} />
    case 'dtc':
      return <img style={{ height: '26px' }} src={'/images/flags/DTC.svg'} />
  }
}

function cc(code) {
  return `https://hatscripts.github.io/circle-flags/flags/${code.toLowerCase()}.svg`
}
